import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import { ApolloConsumer, gql } from '@apollo/client'
import { naSchoolData } from './constants'
import { Text } from '../Typography'

const { Option } = components
const SubtextedOption = (props: any) => (
	<Option {...props}>
		{props.data.id === naSchoolData.id ? (
			<div style={{ textTransform: 'capitalize' }}>
				<Text>{props.data.name.toLowerCase()}</Text>
			</div>
		) : (
			<div style={{ textTransform: 'capitalize' }}>
				<Text>{props.data.name.toLowerCase()}</Text>
				<Text style={{ fontSize: '12px' }}>
					{`${props.data.city.toLowerCase()} ${props.data.state.toUpperCase()}
				${props.data.postalCode}`}
				</Text>
			</div>
		)}
	</Option>
)

const fetchData = (client: any) => async (input: string, cb: any) => {
	const res = await client.query({
		query: gql`
      query {
        schools(name: "${input}") {
          id
          name
          level
          city
          state
          postalCode
        }
      }
    `,
	})

	if (res.data && res.data.schools) {
		const schoolList = res.data.schools.map(
			(school: { name: string; id: string }) => ({
				label: school.name,
				value: school.id,
				...school,
			})
		)
		return schoolList
	}

	return [{ ...naSchoolData }]
}

const AutoSelect = ({
	onChange,
	value,
}: {
	onChange: Function
	value?: any
}) => (
	<ApolloConsumer>
		{(client) => (
			<AsyncSelect
				id="long-value-select"
				instanceId="long-value-select"
				defaultOptions={true}
				loadOptions={fetchData(client)}
				onChange={(opt: any) => onChange(opt)}
				placeholder="Type school name here"
				components={{ Option: SubtextedOption }}
				className="select"
				noOptionsMessage={({ inputValue }) =>
					!inputValue
						? 'Please begin typing your school name'
						: 'No results found'
				}
				// Include optional params
				{...{ value }}
			/>
		)}
	</ApolloConsumer>
)

export default AutoSelect
