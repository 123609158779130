import { CSSProperties, ReactElement } from 'react'
import { useWindowSize } from '../hooks/useWindowSize'
import styles from './WindowHeightContainer.module.css'

export interface Props {
	style?: CSSProperties
	className?: string
	children?:
		| string
		| string[]
		| ReactElement<any>
		| (ReactElement<any> | string)[]
}

const WindowHeightContainer = (props: Props) => {
	const { height } = useWindowSize()

	return (
		<div
			style={{ height: height, ...props.style }}
			{...props}
			className={`${styles.containerWrapper} ${props.className || ''}`}
		/>
	)
}

export default WindowHeightContainer
