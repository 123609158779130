// TODO fetch this from the db instead of just copying the ID value here?
export const naSchoolData = {
	label: 'Not in school / Not found',
	value: '12345678-1234-1234-1234-123456789000',
	id: '12345678-1234-1234-1234-123456789000',
	name: 'Not in school / Not found',
	level: '',
	city: '',
	state: '',
	postalCode: '',
}
